
import { Component, Vue } from 'vue-property-decorator'
import { DetailInfo } from '@/types/devicesCategory'

@Component({
  name: 'DeviceCategoryDetail'
})
export default class extends Vue {
  private detailInfo: DetailInfo | null = null
  created () {
    console.log(this.$route)
    const id = this.$route.params.id as string
    console.log('id: ', id)
    this.getDetail(id)
  }

  getDetail (id: string) {
    this.$axios
      .get(this.$apis.deviceCategory.selectDeviceTypeById, {
        deviceTypeId: id
      })
      .then((res) => {
        this.detailInfo = res
      })
  }
}
